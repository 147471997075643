import { Navigate } from "react-router-dom";
import { auth } from "../firebase/firebase"

export default function Logout() {
    
    auth.signOut();

    return <div>
        <div>LOGOUT</div>
        <Navigate to="/login"/>
    </div>
}