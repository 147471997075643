import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);

export async function userExists(uid) {
  const docRef = doc(db, 'users', uid);
  const res = await getDoc(docRef);
  console.log(res);
  return res.exists();
}

export async function userIsTeacher(uid) {
  const docRef = doc(db, 'teachers', uid);
  const res = await getDoc(docRef);
  console.log(res);
  return res.exists();
}

export async function publicUser(uid) {
  const docRef = doc(db, 'publicUsers', uid);
  const res = await getDoc(docRef);

  if (res.exists()) {
    return res.get('name') !== null && res.get('description') !== null && res.get('type') !== null;
  } else {
    return false;
  }
}

export async function userFactura(uid) {
  const billingData = await getDoc(doc(db, 'users', uid, 'billing', 'data'));

  return !(billingData.data() === undefined || billingData.data() === null);
}

export async function userIsAdmin(uid) {
  const adminds = await getDoc(doc(db, 'admins', uid));

  return adminds.exists();
}

export async function getPresupuestosNum() {
  const presupuestos = await getDoc(doc(db, 'presupuestos', 'data'));

  return presupuestos.get('sinRespuesta').length;
}

const analytics = getAnalytics(app);