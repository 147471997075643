import { Button, CircularProgress } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export function CustomDividerVertical({ color, width, extraMargin="0px", verticalMargin="0px", flex="" }) {
    return <div style={{backgroundColor: color, width: width, margin:`${verticalMargin} ${extraMargin}`, height:flex}}></div>;
}

export function CustomDividerHorizontal({ color, height, extraMargin, horizontalMargin="0px" }) {
    return <div style={{backgroundColor: color, height:height, margin:`${extraMargin} ${horizontalMargin}`}}></div>;
}

export function RoundButton({ text, onClick=()=>{}, endIcon, startIcon, disabled, href }) {
    const [loading, setLoading] = useState(false);

    return <Button href={href} variant="outlined" style={{borderRadius: 90, borderWidth:'2px', width:'-webkit-fill-available'}} color="primary" endIcon={loading? null : endIcon} onClick={() => {
        setLoading(true);
        onClick().then((event) => {
            setLoading(false);
        });
    }} disabled={disabled}>{loading? <CircularProgress/> : text}</Button>
}

export function DisplayTextInfo({ title, text }) {
    return <div>
        <div style={{fontSize: '22px', fontWeight:'700'}}>{title}</div>
        <div style={{fontSize: '20px', fontWeight:'400'}}>{text}</div>
    </div>;
}

export function IconText({ icon, text, route }) {
    const navigate = useNavigate();

    return <div className="icon-text" onClick={() => {navigate(route)}}>
        {icon}
        <CustomDividerHorizontal color="grey" height="1px" extraMargin="3px" horizontalMargin="5px"/>
        <div>{text}</div>
    </div>
}