import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../implementation/navbar';
import { useState } from 'react';
import AuthProvider from '../implementation/auth-provider';
import LoadingBarApp from '../implementation/loading';

import { useTranslation } from "react-i18next";

import '../style/style.css';
import { Divider } from '@mui/material';

export default function PresupuestoView() {
    const {t} = useTranslation();

    const {uid, pid} = useParams();

    const navigate = useNavigate();

    const [state, setCurrentState] = useState(0);
    const [userr, setUser] = useState(null);

    const [presupuesto, setPresupuesto] = useState(null);

    function handleOnUserLoggedIn(user) {
        setUser(user);
        setCurrentState(1);
    }
    function handleUserNotRegistered(user) {
        window.location.href = "https://bitmasters.es";
    }
    function handleUserNotLoggedIn() {
        navigate("/login");
    }

    async function getPresupuesto() {
        //const snapshot = onSnapshot(doc(db, 'presupuestos', 'data'), async (presupuestos) => {
            
        //});
    }

    if (state === 1) {
        if (presupuesto === null) {
            getPresupuesto();

            return <div>
                <Navbar userId={userr != null? userr.uid : null}/>
                <div style={{height:'64px', backgroundColor:'#708090'}}/>
                <div style={{marginLeft:'70px', marginRight:'5px'}}>
                    <h1 className="page-title-p">
                        Presupuesto nº{pid}
                        <div style={{fontSize:'16px', fontWeight:'400'}}>{uid}</div>
                    </h1>
                    <LoadingBarApp/>
                </div>
            </div>;
        } else {
            return <div>
                <Navbar userId={userr != null? userr.uid : null}/>
                <div style={{height:'64px', backgroundColor:'#708090'}}/>
                <div style={{marginLeft:'70px', marginRight:'5px'}}>
                    <h1 className="page-title-p">
                        Presupuestos
                    </h1>
                    <Divider/>
                    
                </div>
            </div>;
        }
    }

    return <AuthProvider
        onUserLoggedIn={handleOnUserLoggedIn}
        onUserNotRegistered={handleUserNotRegistered}
        onUserNotLoggedIn={handleUserNotLoggedIn}
    >
        <LoadingBarApp/>
    </AuthProvider>
}