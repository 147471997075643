import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './routes/homePage';
import NotFoundPage from './routes/notFound';

import './i18n';
import LoginView from './routes/login';
import Logout from './routes/logout';
import Presupuestos from './routes/presupuestos';
import { Presupuesto } from './classes/presupuesto';
import PresupuestoView from './routes/presupuesto';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path='' element={<HomePage/>}/>
      <Route path='/login' element={<LoginView/>}/>
      <Route path='/logout' element={<Logout/>}/>
      <Route path='/budgets' element={<Presupuestos/>}/>
      <Route path='/users/:uid/budgets/:pid' element={<PresupuestoView/>}/>
      <Route path='*' element={<NotFoundPage/>}/>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
