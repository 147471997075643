import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'es',
        interpolation: {
            escapeValue: false,
        },
        resources: {
            es: {
                translation: {
                    development: {
                        presentation: {
                            title: "Lleve su negocio al siguiente nivel con",
                            info: "Aplicaciones web, de control interno, facturación, e-commerce y todo lo que pueda necesitar gracias a nuestros profesionales.",
                            to: "Para autónomos, pequeñas y medianas empresas."
                        }
                    },
                    learning: {
                        presentation: {
                            title: "Aprende lo más novedoso en programación con",
                            info: "Desde el nivel más básico hasta aprender cómo hacer aplicaciones web, aplicaciones móviles, robótica e incluso videojuegos.",
                            to: "Para niñas y niños de 12 a 18 años."
                        }
                    }
                }
            },
            ca: {
                translation: {
                    development: {
                        presentation: {
                            title: "Porti el seu negoci al següent nivell amb",
                            info: "Aplicacions web, de control intern, facturació, e-commerce i tot el que pugui necessitar gràcies als nostres professionals.",
                            to: "Per a autònoms, petites i mitjanes empreses."
                        }
                    },
                    learning: {
                        presentation: {
                            title: "Aprèn el més nou en programació amb",
                            info: "Des del nivell més bàsic fins a aprendre com fer aplicacions web, aplicacions mòbils, robòtica i, inclús, videojocs.",
                            to: "Per a nenes i nens de 12 a 18 anys."
                        }
                    }
                }
            }
        }
    });

export default i18n;