import { Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { User } from "./user";

export class Presupuesto {
    constructor(id, user, fields, observaciones, date, price, responsesNum, responses) {
        this.id = id;
        this.user = user;
        this.fields = fields;
        this.observaciones = observaciones;
        this.date = date;
        this.price = price;
        this.responsesNum = responsesNum;
        this.responses = responses;
    }

    static async fromDataBaseNoFields(docData, user) {
        const userObj = await User.fromFirestoreNoFiscalData(user);

        return new Presupuesto(docData.id, userObj, undefined, undefined, docData.get('time'), docData.get('price'), (docData.get('responds') === null || docData.get('responds') === undefined)? 0 : docData.get('responds').length, undefined);
    }

    static async fromDataBase(docData, user) {
        const responses = [];

        await Promise.all(docData.get('responds').map(async (respond, index) => {
            if (respond.type === 'presu') {
                const respondObj = await PresuRespond.fromDataBase(respond);

                console.log(respondObj);
                responses[index] = respondObj;
            } else if (respond.type === 'userRespond') {
                const respondObj = UserRespond.fromDataBase(respond);

                console.log(respondObj);
                responses[index] = respondObj;
            } else if (respond.type === 'adminRespond') {
                const respondObj = AdminRespond.fromDataBase(respond);

                console.log(respondObj);
                responses[index] = respondObj;
            }
        }));

        const userObj = await User.fromFirestoreNoFiscalData(user);

        return new Presupuesto(docData.id, userObj, docData.get('fields'), docData.get('observaciones'), docData.get('time'), docData.get('price'), (docData.get('responds') === null || docData.get('responds') === undefined)? 0 : docData.get('responds').length, responses);
    }

    getId() {
        return this.id;
    }

    getUser() {
        return this.user;
    }

    getFields() {
        return this.fields;
    }

    getObservaciones() {
        return this.observaciones;
    }

    getDateString() {
        return this.date.toDate().toLocaleDateString('es');
    }

    getPrice() {
        return this.price;
    }

    getResponsesNum() {
        return this.responsesNum;
    }

    getResponses() {
        return this.responses;
    }

    drawTableRow(index, redirect, handleContextMenu) {
        return <TableRow
            key={index}
            sx={{ '&:last-child td, &:last-child th': {border: 0} }}
            className="payments-table-row"
            onClick={() => {
                redirect(`/users/${this.user.getId()}/budgets/${this.id}`);
            }}
            onContextMenu={(event) => {
                handleContextMenu(event, this);
            }}
        >
            <TableCell>{this.id}</TableCell>
            <TableCell>{this.user.getFullName()}</TableCell>
            <TableCell>{this.date.toDate().toLocaleDateString('es')}</TableCell>
            <TableCell>{this.responsesNum}</TableCell>
            <TableCell>{this.price === undefined || this.price === null? "No establecido" : `${this.price} €`}</TableCell>
        </TableRow>
    }

    drawFieldsContent(language) {
        const retVal = [];

        this.fields.map((field, index) => {
            if (field.id != 'divider') {
                retVal.push(<div key={index}>
                    <div style={{fontWeight:'600'}}>{field.title[language]}: <span style={{fontWeight:'400'}}>{field.value===true?"Sí":field.value===false?"No":field.value}</span></div>
                </div>);
            } else {
                retVal.push(<Divider/>);
            }
        });

        return retVal;
    }
}

export class PresuRespond {
    constructor (time, observaciones, fields, price) {
        this.time = time;
        this.observaciones = observaciones;
        this.fields = fields;
        this.price = price;
    }

    static async fromDataBase(data) {
        const fields = [];

        var price = 0;

        await Promise.all(data.fields.map(async (field, index) => {
            const fieldObj = await PresuProduct.fromDataBase(field);

            console.log(fieldObj);
            fields[index] = fieldObj;

            price += fieldObj.getPrice()*fieldObj.getQuantity();
        }));

        return new PresuRespond(data.time, data.observaciones, fields, price);
    }

    getDateString() {
        return this.time.toDate().toLocaleDateString('es');
    }

    drawRespond(language) {
        return <div style={{borderLeft:'1px solid grey', borderRadius:'5px', paddingLeft:'5px'}}>
            <div style={{fontSize:'22px'}}>Presupuesto ({(this.price/100).toFixed(2)} € - {this.getDateString()})</div>
            <div style={{margin:'20px', textAlign:'justify'}}>
                <div>{this.observaciones}</div>
                <div style={{margin:'10px'}}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="payments-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Referencia</TableCell>
                                    <TableCell>Descripción</TableCell>
                                    <TableCell>Cantidad</TableCell>
                                    <TableCell>Valor total (€)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.fields.map((field, index) => {
                                    return field.drawTableRow(index, language);
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            <div style={{height:'5px'}}/>
        </div>;
    }
}

export class PresuProduct {
    constructor (ref, title, description, price, quantity) {
        this.ref = ref;
        this.title = title;
        this.description = description;
        this.price = price;
        this.quantity = quantity;
    }

    static async fromDataBase(productData) {
        const productInfo = await getDoc(doc(db, 'tech_products', productData.ref));
        const productPrice = await getDoc(doc(db, 'products', productData.ref, 'prices', productData.price));

        return new PresuProduct(productData.ref, productInfo.get('title'), productInfo.get('description'), productPrice.get('unit_amount'), productData.quantity);
    }

    getRef() {
        return this.ref;
    }

    getTitle() {
        return this.title;
    }

    getDescription() {
        return this.description;
    }

    getPrice() {
        return this.price;
    }

    getQuantity() {
        return this.quantity;
    }

    drawTableRow(index, language) {
        return <TableRow key={index}>
            <TableCell>{this.getRef()}</TableCell>
            <TableCell><div>
                {this.getTitle()[language]}
                <div style={{fontSize:'12px', color:'grey'}}>{this.getDescription()[language]}</div>
            </div></TableCell>
            <TableCell><div>
                {this.getQuantity()}
                <div style={{fontSize:'12px', color:'grey'}}>{(this.getPrice()/100).toFixed(2)} €</div>
            </div></TableCell>
            <TableCell>{(this.getQuantity() * (this.getPrice()/100)).toFixed(2)} €</TableCell>
        </TableRow>
    }
}

export class UserRespond {
    constructor (text, time) {
        this.text = text;
        this.time = time;
    }

    static fromDataBase(data) {
        return new UserRespond(data.text, data.time);
    }

    getDateString() {
        return this.time.toDate().toLocaleDateString('es');
    }

    drawRespond(language) {
        return <div style={{borderLeft:'1px solid grey', borderRadius:'5px', paddingLeft:'5px'}}>
            <div style={{fontSize:'22px'}}>Has respondido ({this.getDateString()})</div>
            <div style={{margin:'20px', textAlign:'justify'}}>
                <div>{this.text}</div>
            </div>
            <div style={{height:'5px'}}/>
        </div>;
    }
}

export class AdminRespond {
    constructor (text, time) {
        this.text = text;
        this.time = time;
    }

    static fromDataBase(data) {
        return new AdminRespond(data.text, data.time);
    }

    getDateString() {
        return this.time.toDate().toLocaleDateString('es');
    }

    drawRespond(language) {
        return <div style={{borderLeft:'1px solid grey', borderRadius:'5px', paddingLeft:'5px'}}>
            <div style={{fontSize:'22px'}}>BitMasters ha respondido ({this.getDateString()})</div>
            <div style={{margin:'20px', textAlign:'justify'}}>
                <div>{this.text}</div>
            </div>
            <div style={{height:'5px'}}/>
        </div>;
    }
}