import { useNavigate } from 'react-router-dom';
import Navbar from '../implementation/navbar';
import { useEffect, useState } from 'react';
import AuthProvider from '../implementation/auth-provider';
import LoadingBarApp from '../implementation/loading';

import { useMediaQuery } from 'react-responsive';

import { useTranslation } from "react-i18next";
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { db } from '../firebase/firebase';
import { Presupuesto } from '../classes/presupuesto';

export default function HomePage() {
    const {t} = useTranslation();

    const navigate = useNavigate();

    const [state, setCurrentState] = useState(0);
    const [userr, setUser] = useState(null);

    const [windowHeigh, setWindowHeig] = useState(window.innerHeight);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [fakeState, setFakeState] = useState(false);

    const isLaptop = useMediaQuery({
        query: '(min-width: 1000px)'
    });
    const smallScreen = useMediaQuery({
        query: '(min-width: 530px)'
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowHeig(window.innerHeight);
            setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener("resize", handleResize);
    
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    function handleOnUserLoggedIn(user) {
        setUser(user);
        setCurrentState(1);
    }

    function handleUserNotRegistered(user) {
        window.location.href = "https://bitmasters.es";
    }

    function handleUserNotLoggedIn() {
        navigate("/login");
    }

    if (state === 1) {
        return <div>
            <Navbar userId={userr != null? userr.uid : null}/>
            <div style={{height:'64px', backgroundColor:'#708090'}}/>
            
        </div>;
    }

    return <AuthProvider
        onUserLoggedIn={handleOnUserLoggedIn}
        onUserNotRegistered={handleUserNotRegistered}
        onUserNotLoggedIn={handleUserNotLoggedIn}
    >
        <LoadingBarApp/>
    </AuthProvider>
}