import { useNavigate } from 'react-router-dom';
import Navbar from '../implementation/navbar';
import { useEffect, useState } from 'react';
import AuthProvider from '../implementation/auth-provider';
import LoadingBarApp from '../implementation/loading';

import { useMediaQuery } from 'react-responsive';

import { useTranslation } from "react-i18next";
import { collection, doc, getDoc, getDocs, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase/firebase';
import { Presupuesto } from '../classes/presupuesto';

import '../style/style.css';
import { Divider, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

export default function Presupuestos() {
    const {t} = useTranslation();

    const navigate = useNavigate();

    const [state, setCurrentState] = useState(0);
    const [userr, setUser] = useState(null);

    const [presupuestos, setPresupuestos] = useState(null);
    const [selectedPresu, setSelectedPresu] = useState(null);

    const [fakeState, setFakeState] = useState(false);

    const [contextMenu, setContextMenu] = useState(null);

    const handleContextMenu = (event, presu) => {
        event.preventDefault();

        console.log(presu);
        setSelectedPresu(presu);

        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : null,
        );
    };

    const handleClose = () => {
        setContextMenu(null);
        setSelectedPresu(null);
    };

    function handleOnUserLoggedIn(user) {
        setUser(user);
        setCurrentState(1);
    }
    function handleUserNotRegistered(user) {
        window.location.href = "https://bitmasters.es";
    }
    function handleUserNotLoggedIn() {
        navigate("/login");
    }

    async function getPresupuestos() {
        const snapshot = onSnapshot(doc(db, 'presupuestos', 'data'), async (presupuestos) => {
            const presupuestosObj = [];

            await Promise.all(presupuestos.get('sinRespuesta').map(async (docD, index) => {
                const presuData = await getDoc(doc(db, 'users', docD['user'], 'presupuestos', docD['id']));
                const presu = await Presupuesto.fromDataBaseNoFields(presuData, docD['user']);

                presupuestosObj[index] = presu;
            }));

            console.log(presupuestosObj);
            setPresupuestos(presupuestosObj);
        });
    }

    if (state === 1) {
        if (presupuestos === null) {
            getPresupuestos();

            return <div>
                <Navbar userId={userr != null? userr.uid : null}/>
                <div style={{height:'64px', backgroundColor:'#708090'}}/>
                <div style={{marginLeft:'70px', marginRight:'5px'}}>
                    <h1 className="page-title-p">
                        Presupuestos
                    </h1>
                    <LoadingBarApp/>
                </div>
            </div>;
        } else {
            return <div>
                <Navbar userId={userr != null? userr.uid : null}/>
                <div style={{height:'64px', backgroundColor:'#708090'}}/>
                <div style={{marginLeft:'70px', marginRight:'5px'}}>
                    <h1 className="page-title-p">
                        Presupuestos
                    </h1>
                    <Divider/>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="payments-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nº de presupuesto</TableCell>
                                    <TableCell>Cliente</TableCell>
                                    <TableCell>Fecha</TableCell>
                                    <TableCell>Respuestas</TableCell>
                                    <TableCell>Valor total (€)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {presupuestos.map((budget, index) => {
                                    return budget.drawTableRow(index, navigate, handleContextMenu);
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                >
                    <MenuItem onClick={() => {
                        window.open(`${window.location.origin}/users/${selectedPresu.getUser().getId()}/budgets/${selectedPresu.getId()}/print`)
                    }}>Imprimir</MenuItem>
                    <MenuItem onClick={handleClose}>Marcar cómo faborito</MenuItem>
                    <Divider/>
                    <MenuItem onClick={() => {
                        navigate(`/users/${selectedPresu.getUser().getId()}`);
                    }}>Ver usuario</MenuItem>
                </Menu>
            </div>;
        }
    }

    return <AuthProvider
        onUserLoggedIn={handleOnUserLoggedIn}
        onUserNotRegistered={handleUserNotRegistered}
        onUserNotLoggedIn={handleUserNotLoggedIn}
    >
        <LoadingBarApp/>
    </AuthProvider>
}