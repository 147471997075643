import { Link, useNavigate } from 'react-router-dom'
import '../style/navbar.css'
import logo from '../assets/img/LogoTextBM.png';
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Badge, Button, Collapse, Divider, Drawer, FormControl, InputLabel, List, ListItem, ListItemButton, ListItemIcon, Select, ThemeProvider, Tooltip, createTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'
import { grey } from '@mui/material/colors';
import { useState } from 'react';
import { collection, doc, getDoc, getDocs, onSnapshot, query, updateDoc, where } from 'firebase/firestore';
import { auth, db, functions, getPresupuestosNum } from '../firebase/firebase';
import { AttachMoneyOutlined, Inbox, LoginOutlined, Mail, PaymentsOutlined, ReceiptLongOutlined, ShoppingCartOutlined } from '@mui/icons-material';
import LoadingBarApp from './loading';
import { CustomDividerHorizontal, CustomDividerVertical, RoundButton } from './customUi';
import { httpsCallable } from 'firebase/functions';
import { useTranslation } from 'react-i18next';
import { resaltTheme, secondaryTheme } from '../style/theme';
import { useMediaQuery } from 'react-responsive';
import { useEffect } from 'react';

export default function Navbar({ drawer=false, toggleDrawer, drawerIcon, badgeCount, drawerIconTooltip, userId, handleOnCart=(cart) => {console.log(cart)} }) {
    const { t, i18n } = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [fakeState, setFakeState] = useState(false);

    const [optionsOpened, setOptionsOpened] = useState(false);

    const [presupuestos, setPresupuestos] = useState(null);

    const theme = createTheme({
      palette: {
        primary: {
          main: '#36454f'
        }
      }
    });

    const navigate = useNavigate();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener("resize", handleResize);
  
      return () => {
          window.removeEventListener("resize", handleResize);
      };
    }, []);

    const isLaptop = useMediaQuery({
      query: '(min-width: 640px)'
  });

  const selectLanguage = <ThemeProvider theme={theme}>
    <div style={{flex:isLaptop?'':'1', display:'flex', justifyContent:'center'}}>
      <FormControl sx={{ m: 1, minWidth: 120}} size="small">
        <InputLabel id="language-select-lable">Idioma</InputLabel>
        <Select
          MenuProps={{
            sx: {
              "&& .MuiMenu-list": {
                backgroundColor: resaltTheme.palette.secondary.main
              }
            }
          }}
          labelId="language-select-lable"
          id="language-select"
          value={i18n.resolvedLanguage}
          label="Idioma"
          onChange={(event) => {
            i18n.changeLanguage(event.target.value);
          }}
          sx={{color: theme.palette.primary.main}}
        >
          <MenuItem value="es">Español</MenuItem>
          <MenuItem value="ca">Català</MenuItem>
        </Select>
      </FormControl>
    </div>
  </ThemeProvider>;

  const userProv = <div style={{flex:isLaptop?'':'1', display:'flex', justifyContent:'center'}}>
    <IconButton
      size='large'
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={handleMenu}
      color="inherit"
      style={{color:"#36454f", padding:3,}}
      className='button'
    >
      <AccountCircle />
    </IconButton>
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      sx={{
        "&& .MuiMenu-list": {
          backgroundColor: resaltTheme.palette.secondary.main
        }
      }}
    >
      <MenuItem onClick={() => {navigate("/dashboard")}}>Panel de control</MenuItem>
      <MenuItem onClick={handleClose}>Contacto</MenuItem>
      <MenuItem onClick={() => {navigate("/logout");}}>Cerrar sesión</MenuItem>
    </Menu>
  </div>;

  async function getPresupuestos() {
    const snapshot = onSnapshot(doc(db, 'presupuestos', 'data'), (docData) => {
      setPresupuestos(docData.get('sinRespuesta').length>0);
    });
  }

  if (presupuestos === null) {
    getPresupuestos();
  }

  return <div>
    <ul className="navbar">
        {drawer? <Tooltip title={drawerIconTooltip}><Button style={{margin:2, padding:2, minWidth:0}} onClick={toggleDrawer(true)}><ThemeProvider theme={theme}><Badge badgeContent={badgeCount} color='primary'>{drawerIcon}</Badge></ThemeProvider></Button></Tooltip> : <div></div>}
        <div><Link className='navbar' to="/"><div className='button' id='no-hover'>
            <img className='logo' src={logo} style={{maxWidth:!isLaptop?`${windowWidth-55}px`:''}} alt='Logo'/>
        </div></Link></div>
        {isLaptop
          ? <div id='right'>
            {selectLanguage}
            <div style={{width:'10px'}}/>
            {userProv}
          </div>
          : <div id='right'>
            <ThemeProvider theme={theme}>
              <IconButton onClick={() => {
                setOptionsOpened(!optionsOpened)
              }}>
                <MenuIcon/>
              </IconButton>
            </ThemeProvider>
          </div>
        }
    </ul>
    <Collapse
      in={optionsOpened&&!isLaptop}
      sx={{position:'fixed', top:'64px', zIndex:60, width:'-webkit-fill-available', '&& .MuiCollapse-root': {
        backgroundColor: '#708090'
      }}}
    >
      <div style={{backgroundColor:'#708090'}}>
        <CustomDividerHorizontal color={resaltTheme.palette.primary.main} height="2px"/>
        <div style={{height:'5px'}}/>
        <div style={{display:'flex', alignItems: 'center', columnWidth: 'calc(100% / 2)'}}>
          {selectLanguage}
          <CustomDividerVertical color={resaltTheme.palette.primary.main} width="2px" flex="50px"/>
          {userProv}
        </div>
        <div style={{height:'5px'}}/>
        <CustomDividerHorizontal color={resaltTheme.palette.primary.main} height="2px"/>
      </div>
    </Collapse>
    <Drawer 
      variant="permanent"
      sx={{
        backgroundColor:'#708090',
        '& .MuiDrawer-paper': {
          backgroundColor: '#708090',
          zIndex:1,
          marginTop:'64px',
          borderTop: '1px solid #515c68',
        }
      }}
    >
      <ThemeProvider theme={secondaryTheme}>
        <List>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <Tooltip title="Ventas" placement='right'>
              <ListItemButton
                sx={[
                  {
                    minHeight: 48,
                    px: 2.5,
                  }
                ]}
              >
                <ListItemIcon
                  sx={[
                    {
                      minWidth: 0,
                      justifyContent: 'center',
                    }
                  ]}
                >
                  <AttachMoneyOutlined/>
                </ListItemIcon>
              </ListItemButton>
            </Tooltip>
            <Tooltip title="Presupuestos" placement='right'>
              <ListItemButton
                sx={[
                  {
                    minHeight: 48,
                    px: 2.5,
                  }
                ]}
                onClick={() => {navigate("/budgets")}}
              >
                <Badge color='secondary' variant='dot' invisible={presupuestos === null || !presupuestos}>
                    <ReceiptLongOutlined/>
                  </Badge>
              </ListItemButton>
            </Tooltip>
          </ListItem>
        </List>
        <Divider />
      </ThemeProvider>
    </Drawer>
  </div>
}